.about {
    &__items {
        padding: 3rem;

        @media only screen and (max-width: 1200px) {
            padding: 2rem;
        }
    }

    &__text {
        text-align: left;
    }


    h1 {
        margin: 0 0 3.5rem;
        font-size: clamp(2rem, 6vw, 4rem);

        @media only screen and (max-width: 900px) {
            margin: 0 0 1.5rem;
        }
    }

    p {
        margin: 0 0 2rem;

        @media only screen and (max-width: 900px) {
            margin: 0 0 1rem;
        }
    }
}