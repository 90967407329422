.work {
    display: flex;
    justify-content: center;

    &__text {
        margin-bottom: 3rem;
    }

    &__img {
        .img {
            width: 100%;
            height: 100%;
        }

        border-radius: 40px;
    }

    &__left {
        padding: 3rem 3rem 3rem 0;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (max-width: 1200px) {
            padding: 1rem 0;
        }
    }

    &__right {
        padding: 3rem 0 3rem 3rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (max-width: 1200px) {
            flex-direction: column-reverse;
            padding: 1rem 0;
        }
    }

    &__row {

        @media only screen and (max-width: 1200px) {
            :nth-child(1) {
                order: 4;
            }

        }
    }

}

.color-section {
    padding: 10rem 10rem 0;

    &__green {
        background-color: #34BA72;
    }

    &__blue {
        background-color: #455FEA;
    }

    &__purple {
        background-color: #8D84F5;
    }

    &__pink {
        background-color: #DA97DE;
    }

    @media only screen and (max-width: 1800px) {
        padding: 8rem 5rem 0;
    }

    @media only screen and (max-width: 600px) {
        padding: 5rem 1rem 0;
    }
}