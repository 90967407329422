.contact {
    padding: 1rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        display: flex;
        justify-content: center;
    }

    &__items {
        padding: 2rem;
    }

    &__title {
        font-size: 5.5rem;
        line-height: 5.5rem;
    }

    &__elements {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 3rem 0 2rem;
    }

    &__label {
        margin-bottom: 1rem;
    }

    &__button {
        padding: .8rem 4.2rem .8rem;
        width: auto;
        background-color: #121848;
        color: #FFF8FE;
        border-radius: 18px;
    }

    &__link {
        text-decoration: underline;
    }
}

.contact__link:hover {
    font-weight: 600;
    transition: ease-in-out 0.2s;
}