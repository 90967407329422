.header {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;

    .nav {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1.6rem 0;
        position: relative;
        z-index: 1000;

        &__list {
            display: flex;
            align-items: center;
            background: rgba(255, 248, 254, 0);
            padding: 1rem 1.5rem;
            border-radius: 30px;
            transition: background-color 0.4s ease-in-out;
        }

        &__items {
            @media only screen and (max-width: 400px) {
                font-size: 0.7rem;
            }
        }

        button {
            border: none;
            padding: 0.3rem 1rem;
            border-radius: 20px;
            background-color: rgba(255, 248, 254, 0);
            transition: background-color 0.4s ease-out;
        }

        button:hover {
            font-weight: 600;
            transition: ease-in-out 0.2s;
        }

        .active {
            background-color: rgba(255, 248, 254, 1);
            ;
            padding: 0.3rem 1rem;
            border-radius: 20px;
            font-weight: 600;
        }

        a {
            padding: 0.3rem 1rem;
            border-radius: 20px;
            background-color: rgba(255, 248, 254, 0);
            transition: background-color 0.4s ease-out;
        }

        a:hover {
            font-weight: 600;
            transition: ease-in-out 0.2s;
        }

        a.active {
            background-color: rgba(255, 248, 254, 1);
            ;
            padding: 0.3rem 1rem;
            border-radius: 20px;
            font-weight: 600;
        }
    }
}


.dropdown {
    display: none;
}

.show {
    position: absolute;
    top: 4.5rem;
    display: block;
    padding: 20px;
    background: rgba(255, 248, 254, 1);
    border-radius: 20px;

    .nav__items {
        margin: 0.5rem 0 0.5rem;

        a.active {
            background-color: rgba(255, 232, 245, 1);
            ;
            padding: 0.3rem 1rem;
            border-radius: 20px;
            font-weight: 600;
        }
    }
}

.on-scroll {
    background: rgba(255, 248, 254, 0.5) !important;
    backdrop-filter: blur(20px);
    padding: 1rem 1.5rem;
    border-radius: 30px;
    transition: background-color 0.4s ease-in-out;
}