// Google fonts
@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,200;9..144,400;9..144,500;9..144,700;9..144,800&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;400;600;700;800&display=swap');

// Colour Palette
$colour-black: #121848;

$colour-pink-10: #FFF8FE;
$colour-blue-10: #DEE3FF;

$colour-blue-40: #455FEA;
$colour-pink-40: #DA97DE;
$colour-purple-40: #8D84F5;
$colour-green-40: #34BA72;

// Some base styling
* {
    box-sizing: border-box;
}

body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    background: linear-gradient(0deg, rgba(220, 226, 255, 1) 0%, rgba(255, 232, 245, 1) 50%, rgba(220, 226, 255, 1) 100%);
}

header {
    min-height: 5rem;
}

footer {
    min-height: 5rem;
}

main {
    display: block;
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
}

// Gridbox
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@media only screen and (min-width: 75em) {
    .col-lg {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%
    }

    .col-lg-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }

    .col-lg-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }

    .col-lg-3 {
        flex-basis: 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }

    .col-lg-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%
    }

    .col-lg-6 {
        flex-basis: 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%
    }

    .col-lg-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%
    }

    .col-lg-9 {
        flex-basis: 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%
    }

    .col-lg-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%
    }

    .col-lg-12 {
        flex-basis: 100%;
        max-width: 100%
    }
}

@media only screen and (min-width: 64em) {
    .col-md {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%
    }

    .col-md-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%
    }

    .col-md-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%
    }

    .col-md-3 {
        flex-basis: 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%
    }

    .col-md-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%
    }

    .col-md-6 {
        flex-basis: 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%
    }

    .col-md-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%
    }

    .col-md-9 {
        flex-basis: 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%
    }

    .col-md-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%
    }

    .col-md-12 {
        flex-basis: 100%;
        max-width: 100%
    }
}

.col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%
}

.col-xs-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%
}

.col-xs-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%
}

.col-xs-3 {
    flex-basis: 25%;
    max-width: 25%
}

.col-xs-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%
}

.col-xs-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%
}

.col-xs-6 {
    flex-basis: 50%;
    max-width: 50%
}

.col-xs-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%
}

.col-xs-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%
}

.col-xs-9 {
    flex-basis: 75%;
    max-width: 75%
}

.col-xs-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%
}

.col-xs-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%
}

.col-xs-12 {
    flex-basis: 100%;
    max-width: 100%;
}

// Typography
h1 {
    font-family: 'Fraunces', serif;
    font-size: clamp(3rem, 6vw, 12rem);
    color: $colour-black;
}

h2 {
    font-family: 'Work Sans', sans-serif;
    font-size: clamp(1.5rem, 6vw, 6.5rem);
    color: $colour-black;
}

h3 {
    font-family: 'Work Sans', sans-serif;
    font-size: clamp(0.8rem, 6vw, 3.7rem);
    color: $colour-black;
}

p {
    font-family: 'Work Sans', sans-serif;
    font-size: clamp(0.8rem, 6vw, 1.2rem);
    font-weight: 400;
    line-height: 1.8rem;
    color: $colour-black;
}

a {
    font-family: 'Work Sans', sans-serif;
    font-size: clamp(.8rem, 6vw, 1.12rem);
    color: $colour-black;
    text-decoration: none;
}

label {
    font-family: 'Work Sans', sans-serif;
    font-size: clamp(1rem, 6vw, 1.5rem);
    font-weight: 400;
    color: $colour-black;
}

input,
textarea {
    background: none;
    font-family: 'Work Sans', sans-serif;
    font-size: clamp(0.8rem, 6vw, 1.2rem);
    color: $colour-black;
    width: 100%;
    border: 0.15rem solid $colour-black;
    padding: 1rem;
}

form {
    width: 100%;
}

button {
    font-family: 'Work Sans', sans-serif;
    font-size: clamp(.8rem, 6vw, 1.12rem);
    color: $colour-black;
}

ul {
    list-style-type: none;
    padding-inline-start: 0;
}

img {
    display: block;
    width: 100%;
}


// Element groupings
.section {
    display: flex;
    justify-content: center;
    margin: 3rem 0 0rem;
}

.top-section {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 8rem 0 3rem;

    @media only screen and (max-width: 1200px) {
        margin: 12rem 0 1rem;
    }
}

.bot-section {
    display: flex;
    justify-content: center;
    margin: 3rem 0 7rem;

    @media only screen and (max-width: 1200px) {
        margin: 4rem 0 5rem;
    }
}


.wrap {
    width: min(100vw, 121rem);
    margin: 0 auto;
}

.container-fluid {
    padding-left: 16rem;
    padding-right: 16rem;

    @media only screen and (max-width: 1500px) {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    @media only screen and (max-width: 1400px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.center-items {
    justify-content: center;
    align-items: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    margin: 0 auto;
    width: 100%;
}