.footer {
    width: 100%;
    padding: 1rem;
    margin: 2rem 0;

    &__nav {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1.6rem 0;
        position: relative;
        z-index: 1000;
    }

    a {
        font-weight: 700;
        padding: 0 10px;
    }

    &__name {
        padding: 0 2rem;
    }

    &__items {
        padding: 0 0.7rem;
    }

    .logos {
        width: 3rem;
        height: auto;

        img {
            color: #121848;
        }
    }

    &__groups {
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 1130px) {
            margin: 0 0 1rem;
        }

    }

    // &__items {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     margin: 0 5rem;
    // }

    &__row {

        justify-content: center;

        @media only screen and (max-width: 1130px) {
            flex-direction: column;

            :nth-child(2) {
                order: 3;
            }
        }
    }
}